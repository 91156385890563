<template>
    <div class="w3-container" style="padding-top: 40px;padding-bottom:16px; margin-top:40px" v-bind:style="'height:'+  (screenHeight-40) +'px;'">
        <div class="w3-container w3-theme-l5 w3-round-large w3-padding">
            <div id="map">
                <l-map class="w3-round-large" style="height: 52vh; z-index:0" :zoom="map.currentZoom" :center="map.center">
                    <l-tile-layer :url="map.url" :attribution="map.attribution"></l-tile-layer>
                    <l-marker :lat-lng="map.markerLatLng"></l-marker>
                    <l-control position="bottomright" >
                        <span class="w3-btn w3-round-large w3-white" @click="recenterMap" title="Recenter">
                            <i class="fa fa-crosshairs w3-large"></i>
                        </span>
                    </l-control>
                    <l-circle
                        :lat-lng="circle.center"
                        :radius="circle.radius"
                        :color="circle.color"
                        :fill-color="circle.fillColor"
                    >
                        <l-tooltip :options="{ permanent: true, interactive: true }">
                            {{circle.tc_name}}
                        </l-tooltip>
                    </l-circle>
                </l-map>
            </div>
            <div class="w3-row">
                <form method="post" id="fCheckin" v-on:submit.prevent="saveData" action="#" autocomplete="off">
                    <input type="hidden" v-model="rec.userentry">
                    <input type="hidden" v-model="rec.pfunction">
                    <input type="hidden" v-model="rec.sl_lat">
                    <input type="hidden" v-model="rec.sl_lng">

                    <model-list-select
                            :list="customers"
                            class="w3-small"
                            option-value="tc_id"
                            option-text="tc_name"
                            v-model="rec.sl_customer"
                            placeholder="Pilih Customer"
                            v-on:input="setZone()"
                    ></model-list-select>

                    <div class="w3-col l12 m12 s12 w3-margin-top">
                        <div class="w3-col l4 m4 s4" v-on:click="loadCapcha">
                            <input type="text" class="w3-input w3-small w3-round w3-center cursor" style="background-color:#3f51b5;color: white;" id="mainCaptcha" v-model="rec.mainCaptcha" disabled="">
                        </div>
                        <div class="w3-col l4 m4 s4">
                            <input class="w3-input w3-small w3-border-0" name="capcha" v-model="rec.cap" type="text" placeholder="Captcha" required>
                        </div>
                        <div class="w3-col l4 m4 s4">
                            <button  form="fCheckin" class="w3-small w3-btn w3-ripple w3-round w3-green w3-right" type="submit"
                                :disabled="isInside ? false : true"
                            ><i class="fa fa-save"></i> Save</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
/* eslint-disable */
// import { latLng } from "leaflet";
import {
  LMap,
  LTileLayer,
  LMarker,
  LControl,
  LCircle,
  LTooltip
} from "vue2-leaflet";
import { Icon } from "leaflet";
import axios from "axios";
import 'leaflet/dist/leaflet.css';
import { ModelListSelect } from "vue-search-select";
import "vue-search-select/dist/VueSearchSelect.css";

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});
export default {
    // head: {
    //     title: {
    //       inner: 'Main'
    //     }
    // },
    name : 'Checkin',
    components: {
        LMap,
        LTileLayer,
        LMarker,
        LControl,
        LCircle,
        LTooltip,
        ModelListSelect
    },
    data() {
        return {
            screenHeight : 0,
            map: {
                zoom: 8,
                center: [
                    -7.240674, 112.693901
                ],
                url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
                currentZoom: 15,
                markerLatLng: [
                    -7.240674, 112.693901
                ],
                attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
            },
            circle: {
                radius: 0,
                center: [
                    -7.240674, 112.693901
                ],
                color: 'red',
                fillColor: '#f03',
                tc_name: ''
            },
            picked: '',
            pos: [],
            mars: [],
            markers: [],
            pesertas :[],
            pol: {},
            rec: {},
            customers: [],
            isInside : false
        }
    },
    methods : {
        findObj(obj, key, value) {
            let ketemu = 0;
            for (var pkey in obj) {
                if (obj[pkey][key] == value) {
                    break;
                } else {
                    ketemu++;
                }
            }
            if(obj[ketemu]!=undefined){
                return obj[ketemu];
            }else
                return null;
        },
        getLocation() {
            if (navigator.geolocation) {
                // console.log(navigator.geolocation.getCurrentPosition.coords);
                navigator.geolocation.getCurrentPosition(this.showPosition);
            } else { 
                x.innerHTML = "Geolocation is not supported by this browser.";
            }
        },
        showPosition(position) {
            let self = this;
            self.map.center = [
                position.coords.latitude,position.coords.longitude
            ];
            self.map.markerLatLng = [
                position.coords.latitude,position.coords.longitude
            ];

            self.rec.sl_lat = position.coords.latitude;
            self.rec.sl_lng = position.coords.longitude;
        },
        recenterMap(){
            this.map.center = [-7.983908, 112.621391];
            this.map.markerLatLng = [-7.983908, 112.621391];
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(this.showPosition);
            } else { 
                x.innerHTML = "Geolocation is not supported by this browser.";
            }
        },
        async showAdd() {
            let self = this;
            self.stsAdd = true;
            let key = await axios.post("ApiGetId.php",{
                pfunction : 'getId',
                vc_prefix : 'SLC',
                vc_table : 'tb_saleslocation'
            });
            self.rec = {
                pfunction : 'addsaleslocation',
                sl_id : key.data,
                sl_customer : '',
                sl_sales : localStorage.idsales,
                sl_lat : 0,
                sl_lng : 0,
                userentry : localStorage.username,
                mainCaptcha : '',
                cap : ''
            };
            self.circle = {
                // radius: 0,
                // center: [
                //     -7.240674, 112.693901
                // ],
                // color: 'red',
                // tc_name: ''
            },
            self.getLocation();
            self.loadCapcha();
        },
        saveData(event){
            let self = this;
            if (self.rec.mainCaptcha != self.rec.cap || self.rec.cap == ""){
                self.$toast.error('Captcha Invalid');
                return false;
            }else{
                if (self.rec.cust === '') {
                    this.$toast.error('pilih customer.!');
                    return false;
                }else{
                    axios.post("ApiSalesLocation.php",self.rec).then(function (response) {
                        if(response.data.success == true){
                            self.$toast.success('Success.!');
                            self.showAdd();
                        }else{
                            self.$toast.error(response.data.msg);
                        }
                    });
                }
            }
            event.preventDefault();
            return false;
        },
        getDistance(origin, destination) {
            var lon1 = this.toRadian(origin[1]),
                lat1 = this.toRadian(origin[0]),
                lon2 = this.toRadian(destination[1]),
                lat2 = this.toRadian(destination[0]);
            var deltaLat = lat2 - lat1;
            var deltaLon = lon2 - lon1;

            var a = Math.pow(Math.sin(deltaLat/2), 2) + Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(deltaLon/2), 2);
            var c = 2 * Math.asin(Math.sqrt(a));
            var EARTH_RADIUS = 6371;
            return c * EARTH_RADIUS * 1000;
        },
        toRadian(degree) {
            return degree*Math.PI/180;
        },
        setZone(){
            let self = this;
            let objCustomer = self.findObj(self.customers,'tc_id',self.rec.sl_customer);

            self.circle = {
                radius : 75,
                color : 'red',
                fillColor: '#f03',
                center : [
                    objCustomer.tc_lat,
                    objCustomer.tc_lng
                ],
                tc_name : objCustomer.tc_name,
            };

            let distance = self.getDistance(self.map.markerLatLng, self.circle.center)
            if (distance < self.circle.radius) {
                self.isInside = true;
                self.circle.color = 'green';
                self.circle.fillColor = 'green';
                self.$toast.success('Anda berada dalam radius Customer.!');
            }else{
                self.isInside = false;
                self.circle.color = 'red';
                self.circle.fillColor = '#f03';
                self.$toast.error('Anda di luar radius Customer.!');
            }
        },
        async loadCapcha(){
            const obj = await axios({
                method: "post",
                url: "ApiToken.php",
                data: {
                    pfunction : "getcaptcha"
                },
            });
            if (obj.data.captcha === undefined) {
                this.rec.mainCaptcha = obj.data.msg;
            }else{
                this.rec.mainCaptcha = obj.data.captcha;
            }
        },
        setInit() {
            let self = this;
            axios.post("ApiCustomer.php",{
                pfunction : 'setcustomer'
            }).then(function (response) {
                self.customers = response.data.rec;
            });
        }

    },
    mounted(){
        this.$toast.warning('please recenter map.!');
        this.screenHeight = window.innerHeight;
        this.getLocation();
        this.setInit();
        this.showAdd();
    }
};
</script>
